import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1008 1024">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">

<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M58.489719,358.582520 
	C96.934364,249.733719 165.283936,165.510422 263.400970,105.613235 
	C311.957397,75.971161 364.575653,56.576160 420.469452,46.112869 
	C449.012848,40.769562 477.870361,37.844707 506.874939,38.102699 
	C608.070679,39.002838 700.430054,67.954033 782.870911,127.051613 
	C849.095215,174.524353 899.599182,235.339020 934.890259,308.751831 
	C952.807556,346.023773 965.351868,385.110535 972.889832,425.741364 
	C977.354126,449.805023 979.663940,474.118958 980.447144,498.628510 
	C982.790771,571.969421 968.649780,641.989563 938.563110,708.777466 
	C918.993347,752.219482 893.201843,791.707031 861.512573,827.354309 
	C820.496765,873.492737 772.492310,910.465149 717.208618,937.929443 
	C675.760864,958.520203 632.191772,972.620056 586.547791,980.377563 
	C556.574768,985.471802 526.388611,987.976318 496.033081,987.017944 
	C375.913544,983.225281 271.415466,940.865906 183.172104,859.098145 
	C135.709778,815.118958 99.344536,763.120361 73.073914,704.071106 
	C57.642723,669.386047 46.719662,633.242371 40.125511,595.867310 
	C33.327385,557.336060 31.039066,518.478455 33.931599,479.436646 
	C36.981205,438.274689 44.826458,398.023102 58.489719,358.582520 
M107.011467,727.548706 
	C137.337006,783.775696 177.726456,831.548462 228.009659,870.887085 
	C283.523254,914.317688 345.937531,943.156616 414.891724,957.534912 
	C454.680847,965.831848 494.935516,968.526733 535.579163,965.870728 
	C565.071838,963.943420 594.084839,959.496704 622.622009,951.862854 
	C711.221069,928.162109 786.178467,881.983826 846.651245,813.252563 
	C919.717712,730.207947 957.434326,632.793274 960.530640,522.194092 
	C961.498291,487.632233 958.097168,453.353729 951.026123,419.482391 
	C941.074463,371.812317 923.849792,326.925964 899.358521,284.825073 
	C859.900452,216.996307 806.500854,162.739685 739.193176,122.621651 
	C662.109314,76.676582 578.484497,55.500179 488.643219,58.985798 
	C452.344757,60.394089 416.757721,65.899147 381.928070,75.989525 
	C294.398987,101.347267 220.845413,148.731659 161.749115,218.012939 
	C94.811516,296.486938 59.287540,387.791779 53.509892,490.588318 
	C51.637695,523.898682 53.736126,557.173218 59.533142,590.085693 
	C68.000511,638.158936 83.535172,683.847656 107.011467,727.548706 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M486.490845,227.335159 
	C527.888672,224.641190 567.748291,229.875900 606.143738,244.463730 
	C676.722351,271.279144 728.855286,318.810974 763.012146,385.974243 
	C777.857056,415.164062 786.814453,446.247681 790.451660,478.839844 
	C798.420288,550.245056 782.618530,615.899536 741.910156,675.111450 
	C708.000549,724.434448 662.666870,759.680420 606.614685,780.810852 
	C576.936951,791.998840 546.153687,797.675354 514.377380,798.454712 
	C454.442871,799.924744 399.346100,784.454895 349.443939,751.374146 
	C298.334839,717.493408 261.811768,671.637207 239.722275,614.393860 
	C226.997314,581.418030 220.086990,547.127686 221.100006,511.873169 
	C224.298599,400.558472 274.394012,316.625488 370.948975,261.064514 
	C403.673828,242.233536 439.593842,232.287231 477.053375,227.962509 
	C480.026886,227.619202 483.036560,227.588547 486.490845,227.335159 
M414.399017,455.981873 
	C448.095978,392.693604 501.087585,371.950317 569.318909,385.382080 
	C508.400208,352.386292 425.826904,371.021698 384.060028,435.858032 
	C343.424133,498.938690 359.974365,584.281738 421.100433,628.726685 
	C475.722565,668.442505 544.825073,658.533813 576.014648,635.040588 
	C521.355591,650.111938 473.341522,640.489746 435.880707,598.575500 
	C398.553162,556.810364 392.333008,508.423767 414.399017,455.981873 
M531.880310,628.513062 
	C584.452759,630.060913 631.626953,596.335876 646.867920,546.307922 
	C662.010254,496.603577 642.051514,443.257965 598.376404,414.964722 
	C560.366394,390.341431 511.109802,394.614655 491.504578,407.743317 
	C511.382141,404.556305 530.891846,405.398102 549.886047,412.394470 
	C569.135620,419.484955 585.092651,431.025818 597.668457,447.256226 
	C610.150452,463.365631 617.271118,481.694489 619.103149,501.841217 
	C621.331360,526.343750 616.233521,549.559082 602.066956,569.773132 
	C573.634827,610.342407 534.040100,623.782104 485.396881,614.143433 
	C486.914276,618.111633 489.594330,618.550171 491.728760,619.490479 
	C504.224670,624.996094 517.458923,627.354553 531.880310,628.513062 
M477.106720,511.500000 
	C477.169464,522.822327 476.403778,534.223877 477.478119,545.449402 
	C479.356415,565.075928 488.660889,571.410217 503.943085,569.805054 
	C508.482605,569.328186 512.172485,567.461792 515.356384,564.289795 
	C516.587585,563.063171 517.328613,561.133850 519.464844,560.999695 
	C521.448486,567.976685 521.448486,567.983765 529.372498,568.004700 
	C532.036133,568.011719 534.700256,567.938293 537.363586,567.963501 
	C540.525085,567.993408 541.752075,566.469727 541.738159,563.330261 
	C541.664062,546.679504 541.682007,530.028137 541.717896,513.377136 
	C541.725525,509.820892 540.020508,508.177979 536.544739,508.168396 
	C531.882690,508.155548 527.219727,508.052124 522.558716,508.110474 
	C507.030975,508.304779 509.141937,505.247162 508.986877,522.409607 
	C508.954437,525.999023 510.398712,527.665344 513.989380,527.581543 
	C515.806702,527.539062 518.044922,527.343018 518.100891,529.985168 
	C518.209717,535.120911 518.960815,540.313660 517.574341,545.380676 
	C516.398010,549.679626 513.033020,551.769165 508.465912,551.180115 
	C504.180786,550.627380 501.797607,548.017090 501.775635,543.809814 
	C501.670502,523.663086 501.570618,503.513794 501.802277,483.369232 
	C501.877502,476.827057 508.117462,473.266663 513.895508,475.893921 
	C518.158813,477.832367 518.336182,481.622467 518.357788,485.607330 
	C518.407898,494.843781 516.886658,494.845062 527.524109,494.815277 
	C527.857117,494.814331 528.190247,494.825623 528.523132,494.819183 
	C543.903259,494.522858 541.711060,497.141754 541.658813,481.116486 
	C541.621765,469.766663 535.138428,461.674316 524.276306,458.180786 
	C517.959534,456.149200 511.484375,455.888031 505.009338,456.404694 
	C488.751892,457.701813 477.477234,469.743347 477.152649,486.023529 
	C476.990051,494.179413 477.112183,502.341003 477.106720,511.500000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M378.575897,116.607651 
	C384.724701,112.764061 391.357452,111.809074 397.953644,111.048264 
	C402.155884,110.563591 404.558807,113.594299 406.471161,116.916573 
	C413.200897,128.607758 419.928406,140.300247 426.675659,151.981323 
	C427.723206,153.794861 428.869659,155.551254 430.553528,158.278503 
	C431.322083,147.290939 431.975586,137.397751 432.719147,127.511345 
	C433.217621,120.883995 433.797516,114.261818 434.432251,107.646080 
	C434.754272,104.289345 436.738342,102.168037 439.944580,101.293770 
	C444.434784,100.069397 448.902069,98.633514 453.476532,97.876648 
	C459.748108,96.838982 463.399292,99.069923 464.779083,104.914413 
	C469.746307,125.954231 474.591797,147.024338 479.256439,168.133118 
	C480.697327,174.653534 478.129944,178.501816 472.356812,179.869537 
	C467.151550,181.102707 463.176392,178.454391 461.639954,172.141449 
	C457.902527,156.785233 454.382874,141.376022 450.767059,125.990150 
	C450.591125,125.241615 450.766846,124.312477 449.574738,123.792274 
	C448.816986,137.267975 448.081543,150.639359 447.304321,164.008316 
	C447.034149,168.655045 446.702087,173.299164 446.322723,177.938309 
	C445.910767,182.976532 443.205231,186.265961 438.394470,187.721588 
	C433.497101,189.203415 429.108002,188.341614 425.751190,184.269730 
	C422.337036,180.128159 420.242767,175.165359 417.632507,170.532166 
	C411.100983,158.938797 404.704285,147.269455 397.544708,135.842102 
	C399.946716,146.329742 402.342529,156.818817 404.753143,167.304474 
	C406.095245,173.142166 407.574554,178.950974 408.785461,184.815430 
	C409.964417,190.525085 407.403656,194.878372 402.446625,196.089478 
	C396.912292,197.441650 392.527313,195.162262 391.098175,189.242645 
	C385.867340,167.575974 380.911926,145.842560 375.911743,124.120720 
	C375.269196,121.329353 376.168854,118.851707 378.575897,116.607651 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M817.267578,235.753021 
	C821.655029,241.007645 826.129333,245.754593 829.358093,251.447067 
	C834.690735,260.848816 833.856689,271.388855 827.163147,277.762573 
	C822.396851,282.301025 816.786133,284.181061 810.189453,282.624756 
	C808.805054,282.298126 807.413025,281.420776 805.813354,282.137787 
	C805.167358,284.055145 806.318604,285.691284 806.703247,287.398895 
	C809.988770,301.988525 797.247620,314.891907 782.523376,311.885925 
	C776.070068,310.568451 770.825684,307.163788 766.209473,302.702850 
	C756.008118,292.844849 747.119202,281.800323 738.053040,270.937622 
	C733.280334,265.219177 733.669617,261.093323 739.234741,256.180603 
	C754.716309,242.513779 770.313965,228.976440 785.991516,215.534668 
	C791.061890,211.187332 795.569397,211.570526 800.483398,216.778702 
	C806.194336,222.831528 811.534729,229.233978 817.267578,235.753021 
M755.591187,268.882843 
	C761.470154,278.523651 768.069397,287.483734 778.492493,292.751556 
	C780.914734,293.975800 783.316284,293.541321 785.665649,292.334381 
	C791.380554,289.398438 793.207336,284.152649 790.249023,278.051605 
	C786.190247,269.680878 779.248596,263.525391 773.287415,256.623322 
	C771.518982,254.575806 770.106140,255.843521 768.667175,257.116058 
	C764.435242,260.858734 760.187561,264.583679 755.591187,268.882843 
M784.351807,249.228577 
	C789.391724,254.756317 793.641479,261.071686 799.875366,265.414825 
	C804.259766,268.469452 808.651855,268.144592 812.215942,264.782410 
	C815.916992,261.291107 815.348511,257.377991 813.079651,253.415359 
	C809.153381,246.558334 803.619141,240.968689 798.504272,235.050247 
	C797.220093,233.564316 795.900085,233.166824 794.260010,234.665176 
	C790.940247,237.698029 787.588806,240.700394 784.155640,243.603302 
	C782.135986,245.311050 782.314636,246.918533 784.351807,249.228577 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M201.001633,258.158051 
	C199.112579,275.739044 223.153366,293.692108 237.728989,288.866150 
	C249.594940,284.937286 260.063965,271.067047 262.337372,259.424133 
	C263.190613,255.054199 258.196564,253.372375 255.226883,251.291580 
	C252.621933,249.466339 251.667236,252.746872 250.288727,254.113739 
	C247.215607,257.160858 244.412247,260.483582 241.292587,263.479065 
	C238.786728,265.885193 235.753983,266.767029 232.733109,264.365173 
	C229.588486,261.864899 229.350800,258.416107 231.503281,255.425552 
	C237.440247,247.176956 244.008240,239.401199 251.554764,232.572708 
	C254.768524,229.664688 258.794037,229.839142 262.144196,232.512695 
	C267.215759,236.559967 272.050507,240.909958 276.898834,245.229004 
	C280.369537,248.320801 280.193359,252.410019 279.198486,256.375977 
	C274.324554,275.804626 263.878448,291.504639 246.554779,301.787506 
	C230.346664,311.408295 212.097366,308.861389 198.115936,296.051361 
	C183.343491,282.516571 179.452469,265.232880 187.822693,247.569000 
	C194.181427,234.149933 203.417587,223.121506 217.304352,216.839996 
	C222.972900,214.275879 228.905640,212.727203 235.246597,214.624863 
	C239.233185,215.817947 242.339020,217.723007 242.514450,222.333450 
	C242.693329,227.034592 240.366348,230.261856 236.135239,232.104446 
	C234.286560,232.909515 232.301071,233.065414 230.256744,232.947754 
	C214.530289,232.042450 202.684158,242.104599 201.001633,258.158051 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M296.457275,197.794281 
	C279.710022,196.510086 270.821777,178.117157 282.501801,163.735443 
	C293.297485,150.442627 307.446503,142.928604 325.116119,143.760468 
	C330.281921,144.003662 334.832947,146.013733 338.176422,150.163269 
	C340.788422,153.405045 341.028137,156.914627 338.595825,160.296158 
	C336.131775,163.721786 332.709717,165.165771 328.529724,163.870453 
	C326.958191,163.383453 325.506409,162.491974 324.024353,161.737137 
	C314.495789,156.884109 304.631897,158.917389 297.677887,167.206085 
	C295.141663,170.229065 293.733612,173.803680 295.889374,177.312149 
	C297.914307,180.607727 301.609131,180.937180 305.276367,180.283554 
	C315.761383,178.414871 325.766022,174.473846 336.373322,173.119690 
	C345.523865,171.951508 353.787506,173.421768 359.417694,181.520966 
	C365.219391,189.866791 364.959045,198.565109 360.346832,207.456573 
	C353.226166,221.183853 325.655823,235.948135 310.369537,231.233826 
	C305.182526,229.634140 300.307373,227.635010 296.946289,223.140182 
	C294.387787,219.718643 293.974915,216.144211 296.766876,212.586838 
	C299.477386,209.133270 303.994690,208.018967 307.940369,209.970428 
	C309.722015,210.851608 311.358612,212.031311 313.041504,213.106110 
	C323.967468,220.083908 340.859863,214.020050 344.984467,201.633362 
	C346.943665,195.749634 342.508942,190.412643 335.438293,190.396118 
	C331.069580,190.385895 326.957458,191.930771 322.781677,193.041870 
	C314.272675,195.305954 305.846466,198.042099 296.457275,197.794281 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M595.417114,118.334549 
	C591.158081,117.886902 587.321533,117.537010 583.691345,119.256767 
	C578.562683,121.686409 577.672607,126.605751 581.697693,130.613144 
	C583.960754,132.866287 586.780151,134.339081 589.629211,135.754333 
	C596.934265,139.382935 604.587830,142.297913 611.576599,146.576462 
	C622.884460,153.499130 627.200073,161.708923 624.859741,172.008926 
	C622.440552,182.655838 613.105713,190.162033 600.793457,191.196640 
	C589.777466,192.122345 579.182556,190.236176 569.162476,185.631805 
	C560.094177,181.464767 553.593506,174.878616 551.236816,164.849182 
	C549.688477,158.259796 551.812134,153.833267 557.078552,152.450958 
	C562.419739,151.049026 566.891968,153.566666 568.351807,160.109283 
	C571.359131,173.587082 579.896179,177.441544 593.051392,177.655426 
	C595.609253,177.696991 597.997498,176.877411 600.201599,175.667603 
	C606.441040,172.242783 607.258972,165.588394 602.006470,160.795074 
	C598.760071,157.832458 594.720642,156.148163 590.789185,154.346848 
	C583.822266,151.154724 576.666321,148.269226 570.486511,143.662018 
	C563.774780,138.658279 558.967163,132.464294 560.394958,123.361488 
	C562.035645,112.901245 568.513489,106.530365 580.502625,105.083916 
	C594.260620,103.424042 607.408630,105.848305 619.085632,113.891861 
	C623.899170,117.207611 627.353699,121.597519 628.819824,127.362381 
	C630.165161,132.652313 628.028320,137.412613 623.673401,138.944534 
	C618.660522,140.707886 613.951782,138.834381 612.057251,133.306671 
	C609.285034,125.218491 604.750061,119.565872 595.417114,118.334549 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M693.030212,218.723816 
	C701.645447,218.417450 705.583740,212.523727 709.098389,206.389709 
	C715.716370,194.839432 722.004944,183.100601 728.596985,171.535080 
	C731.947693,165.656464 736.191833,164.210815 741.481689,166.642639 
	C747.084167,169.218155 749.441467,174.361420 746.538513,179.790665 
	C738.775513,194.309448 731.505920,209.145828 722.243408,222.790665 
	C714.789673,233.771027 703.079895,237.765732 690.245483,234.142593 
	C678.501953,230.827438 668.212402,224.763870 659.579651,216.128677 
	C651.711060,208.257812 649.416870,198.835953 652.676514,188.134079 
	C655.719116,178.144775 661.765747,169.655319 666.522583,160.525391 
	C669.674011,154.476883 672.955750,148.483292 676.472534,142.641724 
	C680.103699,136.610062 684.952515,135.198273 690.320435,138.159409 
	C695.736816,141.147278 697.064941,146.444901 693.647705,152.753510 
	C687.544800,164.019974 681.267395,175.191849 675.074707,186.409760 
	C674.351440,187.720047 673.654724,189.049255 673.026245,190.406982 
	C668.093262,201.063950 670.385803,208.459839 680.521912,214.371155 
	C684.272827,216.558685 688.187744,218.387970 693.030212,218.723816 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M604.675659,877.334167 
	C599.684387,865.828796 596.496643,854.099731 592.901245,842.511108 
	C592.066650,839.821106 593.218994,837.674255 595.723022,836.234802 
	C602.843262,832.141602 606.483826,832.862366 611.270935,839.506836 
	C614.731995,844.310791 618.119446,849.167786 622.080750,854.765564 
	C622.923523,847.356323 621.701050,841.087341 621.832947,834.801453 
	C621.972961,828.127502 628.442749,822.744934 634.889587,824.079773 
	C637.293091,824.577454 638.517761,826.251343 639.232849,828.439331 
	C642.540527,838.560242 645.899963,848.664551 649.136475,858.808105 
	C650.234985,862.250977 650.267639,865.679321 646.021912,866.988953 
	C641.821289,868.284607 639.920593,865.507263 638.754211,862.052124 
	C636.908508,856.584900 635.125183,851.096680 632.742493,845.723999 
	C632.742493,850.433960 632.649780,855.146423 632.780212,859.852783 
	C632.849121,862.341675 633.342163,864.821411 632.805847,867.309082 
	C632.194031,870.146667 630.535400,872.104431 627.761475,872.987915 
	C624.823059,873.923706 622.446228,872.808960 620.753296,870.469788 
	C616.469177,864.550171 612.315369,858.536194 607.455139,852.745056 
	C609.409119,858.874695 611.336487,865.013000 613.329529,871.129883 
	C614.177856,873.733215 614.163208,876.106812 611.711670,877.814941 
	C609.462097,879.382507 607.132446,879.814270 604.675659,877.334167 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M713.484741,826.821106 
	C706.576233,817.833252 699.955627,809.091431 693.313171,800.366211 
	C690.562805,796.753357 691.070312,793.572388 694.601929,790.885498 
	C699.494629,787.163086 704.477783,783.559509 709.382812,779.852966 
	C718.862122,772.690002 730.648010,775.674316 730.297974,790.131042 
	C732.683899,790.546509 735.262207,790.560852 737.478516,791.463684 
	C743.869690,794.067200 746.285339,802.780151 741.838684,808.816956 
	C735.836914,816.964722 727.148743,822.155762 718.877258,827.695068 
	C717.219543,828.805176 715.347168,828.306030 713.484741,826.821106 
M715.465515,810.398926 
	C717.032043,812.280457 718.009705,814.672913 720.476013,816.159180 
	C724.586731,813.814148 728.707397,811.353638 731.505859,807.346069 
	C733.833862,804.012329 732.357849,800.330688 728.698181,799.638062 
	C724.931702,798.925293 715.928406,805.708069 715.465515,810.398926 
M705.796570,797.739868 
	C706.835938,798.978394 707.471436,800.628662 709.421448,801.229187 
	C712.740906,799.225708 716.092102,797.093750 718.655884,794.009705 
	C720.143555,792.220215 720.544312,790.038208 719.008118,788.051697 
	C717.501221,786.103088 715.472046,786.588379 713.570679,787.440552 
	C710.665100,788.742737 708.197632,790.698303 705.828247,792.797791 
	C704.286560,794.163635 704.283264,795.600281 705.796570,797.739868 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M257.209595,736.746887 
	C261.695190,741.682007 266.396820,746.021118 270.049072,751.113037 
	C275.286469,758.414978 272.167175,767.192017 264.278351,768.883850 
	C262.855225,769.188965 261.357361,769.145691 259.519836,769.293335 
	C260.355652,773.381775 260.515961,777.285156 257.678528,780.627075 
	C253.512070,785.534363 246.577087,786.455627 240.552322,782.204956 
	C233.456772,777.198730 227.857162,770.526428 222.181168,764.024414 
	C219.872879,761.380127 220.284546,758.484131 222.821991,756.015259 
	C230.444580,748.598755 238.005569,741.116333 245.746231,733.824890 
	C249.441666,730.343872 251.397430,730.944153 257.209595,736.746887 
M236.802734,758.359802 
	C235.366409,759.895569 233.254486,760.884399 232.709320,763.544800 
	C235.660080,766.728577 238.472351,770.235474 242.295914,772.674988 
	C245.270676,774.572998 248.447830,774.464539 249.922638,771.102539 
	C251.837555,766.737305 248.151184,763.915344 245.506332,761.461914 
	C243.391541,759.500183 242.019058,754.390076 236.802734,758.359802 
M261.679779,756.335632 
	C261.620056,752.189575 258.250214,750.147217 255.990021,747.405029 
	C253.848633,744.807068 251.974838,743.588745 248.782303,746.672058 
	C245.529449,749.813660 246.963806,751.679993 249.379776,753.890259 
	C251.082321,755.447937 252.650620,757.204773 254.536346,758.497009 
	C257.201324,760.323120 259.809845,760.423279 261.679779,756.335632 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M368.467712,854.555847 
	C371.488098,846.207153 374.291504,838.194702 377.285767,830.254150 
	C379.409393,824.622375 381.589264,823.555969 387.276978,825.565430 
	C394.018585,827.947266 400.856445,830.217163 407.272522,833.321106 
	C416.327362,837.701599 417.943024,847.903137 411.012848,853.810181 
	C408.365082,856.066956 404.991394,856.288452 401.404297,856.745850 
	C402.699097,862.046753 405.226257,866.463806 405.493744,871.493530 
	C405.640717,874.257263 406.040253,877.115234 402.588165,877.996948 
	C399.202789,878.861633 396.483765,877.246948 395.331879,874.169189 
	C393.765167,869.983276 392.820953,865.564453 391.606323,861.246582 
	C390.266632,856.484192 387.921753,852.729492 382.299316,851.955688 
	C380.088013,855.020874 379.390869,858.657959 378.019409,861.990479 
	C376.050781,866.774353 373.360046,868.536255 369.807098,866.966675 
	C365.694946,865.150024 365.525848,861.899597 367.114288,858.198364 
	C367.573151,857.129150 367.929138,856.015869 368.467712,854.555847 
M394.619843,836.970764 
	C393.841431,836.695435 393.024109,836.497925 392.291138,836.131592 
	C388.650543,834.312500 387.351013,836.378906 386.203552,839.428101 
	C385.063019,842.458862 385.311523,844.475037 388.734558,845.524292 
	C391.884857,846.490051 394.902252,847.916260 398.302032,847.884521 
	C400.404724,847.864868 402.268707,847.572632 402.980988,845.151917 
	C403.703186,842.697266 402.753540,840.864685 400.740692,839.670044 
	C399.050385,838.666809 397.143951,838.027771 394.619843,836.970764 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M648.734863,824.160828 
	C653.039490,813.099487 664.282166,805.377441 675.582275,805.367615 
	C684.851074,805.359619 692.545288,811.312256 696.280334,821.380737 
	C699.483398,830.014954 697.151306,839.244141 689.973267,845.336731 
	C685.074951,849.494385 679.477417,852.475098 673.005554,853.216675 
	C657.877686,854.950317 645.131653,839.873047 648.734863,824.160828 
M673.506531,843.668518 
	C681.858215,842.403564 687.282227,836.753601 686.533203,830.099304 
	C685.514160,821.046143 678.555725,814.449402 670.869202,815.249451 
	C663.902527,815.974609 658.845764,821.764282 659.322571,828.469604 
	C659.871094,836.181702 664.985779,841.973328 673.506531,843.668518 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M354.336487,810.513428 
	C363.417084,816.261353 368.340454,823.725830 366.225189,834.531738 
	C364.339661,844.163757 356.899841,851.817749 347.915466,853.553650 
	C336.069550,855.842285 320.416382,845.863281 317.659668,834.265320 
	C314.953247,822.878906 322.669434,809.737122 334.022827,806.442078 
	C341.342468,804.317688 347.771637,806.865723 354.336487,810.513428 
M336.566772,816.880615 
	C332.679016,819.249207 330.393066,822.773621 329.119507,827.067932 
	C326.900909,834.548950 330.187103,841.193909 337.203613,843.325439 
	C343.323822,845.184692 348.147888,843.441345 351.913086,838.009705 
	C356.364624,831.587952 356.822601,825.634521 353.224487,820.961426 
	C349.568573,816.213257 343.215759,814.449097 336.566772,816.880615 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M809.090576,741.531921 
	C806.698059,742.748901 804.847656,742.054626 803.130249,740.906006 
	C794.407776,735.072449 785.677002,729.250305 777.013306,723.330322 
	C773.362793,720.835938 772.739014,717.617615 775.290100,713.871033 
	C779.977356,706.987000 784.580200,700.044861 789.325623,693.201416 
	C790.957642,690.847839 792.977417,688.225098 796.229431,690.478699 
	C799.521240,692.760071 797.604614,695.481445 796.044250,697.885193 
	C792.532288,703.294983 788.952209,708.660400 785.483643,713.916992 
	C791.268494,719.803711 791.866577,719.772583 795.870300,713.929688 
	C798.317566,710.358337 800.678711,706.721375 803.274048,703.260559 
	C804.863098,701.141479 807.086487,699.572205 809.666870,701.606323 
	C811.932556,703.392334 811.310913,705.728333 809.885254,707.871826 
	C807.856140,710.922852 805.864380,714.000183 803.772766,717.007874 
	C798.716125,724.278931 799.104004,725.587158 807.944824,729.257080 
	C811.072205,724.709778 814.255920,720.070068 817.451965,715.438965 
	C818.492188,713.931702 819.475342,712.372314 820.644592,710.970215 
	C822.138489,709.178955 824.041077,708.038696 826.316406,709.482483 
	C828.763550,711.035217 828.822998,713.387817 827.408386,715.596863 
	C823.548645,721.624573 819.558716,727.569763 815.565186,733.510498 
	C813.714966,736.262817 812.160156,739.263794 809.090576,741.531921 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M837.723267,673.428223 
	C834.129028,670.970947 831.820129,672.818970 829.691467,675.164673 
	C826.674988,678.488708 823.871704,682.023987 820.679932,685.166992 
	C816.298401,689.481567 811.368713,690.427734 807.075134,688.234802 
	C802.420898,685.857666 799.950195,681.213135 800.818420,675.218506 
	C801.874634,667.926392 805.039307,661.562622 810.807800,656.787354 
	C812.305237,655.547607 814.181641,654.575806 816.044617,654.006165 
	C820.169373,652.744873 824.043640,654.475403 824.533264,657.745911 
	C825.194946,662.165283 822.171387,663.880005 818.596436,664.069458 
	C813.212402,664.354797 811.147217,667.867371 809.675354,672.117126 
	C809.015442,674.022339 808.846497,676.199158 810.736572,677.616455 
	C812.904297,679.242004 814.592285,677.603638 815.925171,676.207703 
	C819.137512,672.843323 822.053467,669.195435 825.273010,665.838379 
	C831.087341,659.775696 835.995483,658.616455 841.593079,661.731445 
	C847.003113,664.741943 849.626526,671.114441 847.680420,678.210938 
	C845.096191,687.634399 841.093933,696.173035 830.226257,698.545349 
	C826.609497,699.334839 822.989380,699.114319 821.801147,694.704346 
	C820.836487,691.123962 823.457581,688.693970 828.540771,687.968140 
	C836.862122,686.780029 840.395996,681.448120 837.723267,673.428223 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M181.951721,698.248413 
	C171.951477,694.011597 164.894547,682.743286 165.598114,672.673950 
	C165.811966,669.613464 166.591370,666.740234 168.707397,664.431335 
	C170.737610,662.216064 173.100861,660.481873 176.065308,662.734619 
	C178.736053,664.764282 179.106064,667.617249 177.208084,670.261536 
	C174.143921,674.530457 173.625946,678.711670 176.514481,683.216003 
	C177.431519,684.645996 178.498871,685.891907 179.974518,686.738220 
	C183.723297,688.888062 186.536957,687.341919 186.780441,683.045776 
	C186.925659,680.483582 186.034073,678.135986 185.389175,675.729858 
	C184.187790,671.247498 182.602127,666.808594 183.496323,662.040344 
	C185.073837,653.628174 193.503067,649.641968 200.556656,654.438660 
	C208.128708,659.587830 212.148453,667.141174 212.666336,676.331421 
	C212.764313,678.070251 212.176895,680.002197 211.404205,681.602905 
	C209.876328,684.768250 207.651733,687.609253 203.648132,685.613464 
	C200.103592,683.846436 199.969925,680.624084 201.896484,677.514343 
	C204.355240,673.545593 203.700439,669.917419 201.213684,666.369629 
	C199.835754,664.403748 198.104492,662.430786 195.465302,663.485107 
	C192.625473,664.619629 193.287933,667.216370 193.868073,669.520569 
	C194.883423,673.553223 196.136566,677.536865 196.938766,681.610229 
	C199.120987,692.690674 193.543640,698.986450 181.951721,698.248413 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M225.310516,692.547913 
	C228.471802,696.601624 226.772476,699.388733 223.550385,701.653992 
	C217.696091,705.769836 211.618835,709.579834 205.889694,713.859009 
	C200.675446,717.753540 200.265182,721.424561 203.763214,726.749084 
	C207.360001,732.223999 211.242264,733.228516 216.857300,729.695557 
	C222.630997,726.062744 228.188858,722.088318 233.877289,718.317810 
	C237.013916,716.238708 240.215912,714.933655 243.020523,718.832275 
	C245.356247,722.079102 244.520111,725.052185 240.259857,727.938721 
	C233.922882,732.232239 227.628891,736.617981 221.057266,740.528625 
	C214.241470,744.584656 208.223709,743.694641 202.319061,738.368713 
	C197.512039,734.032837 194.230133,728.641235 192.205811,722.557495 
	C190.245300,716.665527 191.435364,711.306335 196.101990,707.058228 
	C202.782639,700.976807 210.614273,696.490295 218.155075,691.619629 
	C220.443390,690.141602 222.911865,690.253906 225.310516,692.547913 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M440.144196,886.771240 
	C434.627411,884.229980 433.888489,882.412781 436.645477,877.930908 
	C442.491882,868.426758 448.453796,858.993469 454.396667,849.548950 
	C456.508392,846.192932 458.950378,843.251648 463.528015,843.845398 
	C468.238129,844.456360 470.107391,847.826172 471.315399,851.892273 
	C474.300842,861.941162 477.343140,871.973145 480.361450,882.012329 
	C480.601105,882.809448 480.874268,883.599609 481.059937,884.409485 
	C481.835815,887.794006 480.884644,890.462463 477.445251,891.594482 
	C474.114899,892.690735 472.207397,890.726196 470.689911,887.995911 
	C469.203094,885.320923 470.000671,880.978943 466.392822,880.006958 
	C461.450745,878.675598 456.276398,878.130920 451.168976,877.523621 
	C448.940430,877.258667 448.314850,879.528503 447.376282,881.021973 
	C445.691620,883.702759 444.339996,886.708374 440.144196,886.771240 
M459.212982,860.864136 
	C457.800690,863.406860 455.812469,865.697021 455.360687,868.976868 
	C458.694183,870.214966 461.700867,871.324158 465.294678,870.127075 
	C464.563873,865.824158 463.901947,861.731323 461.706665,857.569031 
	C460.771606,858.755127 460.168762,859.519836 459.212982,860.864136 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M285.239380,815.589661 
	C282.341888,817.946411 279.761200,817.922546 277.401764,815.515808 
	C275.018433,813.084717 275.286591,810.450012 277.163422,807.843506 
	C283.479706,799.071716 289.795715,790.299744 296.138611,781.547180 
	C298.541565,778.231384 301.624054,777.867065 304.829956,780.132019 
	C311.346710,784.736084 317.796295,789.440674 324.150543,794.265930 
	C326.127625,795.767334 328.209015,797.809265 326.004822,800.543579 
	C323.854156,803.211426 321.337982,802.363892 318.948669,800.631042 
	C314.261292,797.231689 309.562653,793.847839 304.948517,790.515198 
	C302.030975,792.130859 301.017639,794.637573 299.375488,796.885620 
	C301.285828,800.204590 304.792450,801.372070 307.351776,803.613525 
	C308.848511,804.924561 310.582550,805.971130 312.035522,807.323792 
	C313.601929,808.781921 314.505188,810.623352 312.985260,812.625427 
	C311.406433,814.705078 309.273865,814.807312 307.232605,813.433533 
	C302.970398,810.565063 298.805389,807.552246 294.443756,804.488525 
	C290.643311,807.617798 288.765259,812.050232 285.239380,815.589661 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M521.570435,882.855774 
	C524.998840,883.179443 528.072449,883.887207 527.993225,887.525391 
	C527.913208,891.202209 524.565491,891.868103 521.758850,891.963440 
	C513.768738,892.234619 505.766418,892.248474 497.770111,892.192871 
	C493.961151,892.166382 491.925323,889.829346 491.814484,886.154968 
	C491.492981,875.499084 491.289551,864.838989 491.135345,854.179077 
	C491.081604,850.461304 492.449341,847.409912 496.650970,847.213806 
	C501.184265,847.002075 502.700317,850.166504 502.830383,854.054749 
	C503.086731,861.714478 503.409210,869.387390 503.205200,877.041504 
	C503.084503,881.568604 504.425568,883.482849 509.127167,883.060120 
	C513.098877,882.703125 517.120056,882.897461 521.570435,882.855774 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M764.053955,767.959778 
	C758.809448,763.560425 753.826416,759.399963 748.864197,755.214722 
	C745.849060,752.671631 744.294189,749.762817 747.250183,746.212219 
	C750.244446,742.615723 753.278809,743.769165 756.324524,746.320312 
	C763.575073,752.393433 770.874146,758.408691 778.568909,764.791809 
	C781.851013,760.954834 784.838257,757.442322 787.850037,753.950989 
	C789.153015,752.440491 790.403992,750.862427 791.864197,749.516296 
	C793.555786,747.956665 795.596619,747.023682 797.674316,748.797058 
	C799.826050,750.633789 799.760254,752.965454 798.130920,755.039551 
	C794.328979,759.879272 790.450989,764.664917 786.454407,769.344910 
	C777.931335,779.325195 777.892151,779.292358 767.795654,771.042480 
	C766.635620,770.094604 765.481201,769.139771 764.053955,767.959778 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M558.296631,888.399475 
	C553.419373,889.063843 548.990234,889.743042 544.544189,890.282288 
	C540.625244,890.757629 538.005798,889.093689 537.406006,885.094055 
	C535.781677,874.262573 534.207764,863.422119 532.778809,852.563599 
	C532.304382,848.958191 533.842712,846.257385 537.747375,845.838745 
	C541.660156,845.419250 543.645142,847.923340 544.182068,851.359375 
	C545.410400,859.220642 546.434570,867.116577 547.386230,875.017151 
	C547.842712,878.806458 548.648254,881.330017 553.523743,880.080627 
	C557.671753,879.017578 562.056641,878.893250 566.328308,878.293823 
	C569.381470,877.865417 572.372681,878.139587 573.110535,881.608032 
	C573.982361,885.706238 570.630859,886.554565 567.613464,887.128601 
	C564.683167,887.686218 561.698303,887.956848 558.296631,888.399475 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M740.366943,762.398193 
	C747.800354,769.833801 754.954102,777.017700 762.143188,784.166077 
	C765.007263,787.013794 766.945923,790.131653 763.469421,793.607971 
	C760.009827,797.067261 756.779907,795.157898 753.959290,792.350586 
	C746.656128,785.081543 739.373840,777.791138 732.132507,770.460693 
	C729.582214,767.879028 728.066895,764.938538 731.047180,761.730713 
	C734.105103,758.439392 737.204651,759.215088 740.366943,762.398193 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M891.877136,533.244995 
	C895.202393,537.602783 894.972900,541.610535 891.505737,545.205811 
	C888.725159,548.089050 885.312622,548.921692 881.534302,546.998169 
	C877.622253,545.006531 876.228760,541.694580 876.695679,537.510559 
	C877.416565,531.050598 886.393738,527.251770 891.877136,533.244995 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M125.826859,547.374512 
	C122.576500,545.961792 120.420288,543.960022 119.884911,540.749451 
	C119.226181,536.799194 120.425919,533.539062 124.039467,531.429688 
	C127.644165,529.325562 131.086014,529.655029 134.082001,532.491211 
	C136.972687,535.227722 138.175446,538.645569 136.330490,542.407654 
	C134.301834,546.544312 130.984482,548.620117 125.826859,547.374512 
z"/>
</g>
</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
